import React, { useState } from "react";

const AddEventPage = () => {
  // State to manage event data
  const [eventData, setEventData] = useState({
    title: "",
    description: "",
    date: "",
    time: "",
    category: "",
    location: "",
  });

  const [locationSuggestions, setLocationSuggestions] = useState([]);

  // Function to handle changes in form fields
  const handleChange = (event) => {
    const { name, value } = event.target;
    setEventData({ ...eventData, [name]: value });

    // Filter location suggestions based on user input
    const filteredLocations = locations.filter((location) =>
      location.label.toLowerCase().includes(value.toLowerCase())
    );
    setLocationSuggestions(filteredLocations);
  };

  // Define dropdown options for category and location
  const categories = [
    { value: "simcha", label: "שמחה בבית רבינו" },
    { value: "tish", label: "טיש" },
    { value: "generalKinus", label: "מסיבה ציבורי" },
  ];

  const locations = [
    { value: "office", label: "Office" },
    { value: "online", label: "Online" },
    { value: "other", label: "Other" },
  ];

  return (
    <div dir="rtl" className="add-event-page">
      <h1>לייג צו א נייע מאורע</h1>
      <form onSubmit={(e) => e.preventDefault()}>
        <label htmlFor="category">סארט:</label>
        <select
          id="category"
          name="category"
          onChange={handleChange}
          className="event-dropdown"
        >
          {/* Options populated from categories array */}
          <option key="" value="">
            וועל אויס די סארט מאורע...
          </option>
          {categories.map((category) => (
            <option key={category.value} value={category.value}>
              {category.label}
            </option>
          ))}
        </select>{" "}
        <label htmlFor="title">קעפל:</label>
        <input
          type="text"
          id="title"
          name="title"
          value={eventData.title}
          onChange={handleChange}
          required
          className="event-input"
        />
        <label htmlFor="description">דעסקריפציע:</label>
        <textarea
          id="description"
          name="description"
          value={eventData.description}
          onChange={handleChange}
          rows="5"
          className="event-textarea"
        ></textarea>
        <label htmlFor="date">דאטום:</label>
        <input
          type="date"
          id="date"
          name="date"
          value={eventData.date}
          onChange={handleChange}
          required
          className="event-input"
        />
        <label htmlFor="time">צייט:</label>
        <input
          type="time"
          id="time"
          name="time"
          value={eventData.time}
          onChange={handleChange}
          required
          className="event-input"
        />
        <label htmlFor="location">לאקאציע:</label>
        <input
          type="text"
          id="location"
          name="location"
          value={eventData.location}
          onChange={handleChange}
          className="event-input"
          list="location-suggestions"
        />
        <datalist id="location-suggestions">
          {locationSuggestions.map((location) => (
            <option key={location.value} value={location.value}>
              {location.label}
            </option>
          ))}
        </datalist>
        <button type="submit" disabled>
          סעיוו
        </button>
      </form>
    </div>
  );
};

export default AddEventPage;
