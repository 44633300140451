import React from "react";
import { Modal } from "react-bootstrap"; // Assuming you use React Bootstrap for modals
import AddEventPage from "./AddEventPage";

const EventModal = ({ show, onHide }) => {
  // Your existing AddEventPage component here (replace the placeholder with your actual component)
  //   const AddEventPage = () => {
  //     // ... your AddEventPage component code
  //   };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Add Event</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AddEventPage /> {/* Replace with your actual AddEventPage component */}
      </Modal.Body>
    </Modal>
  );
};

export default EventModal;
