import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap"; // Assuming you use React Bootstrap for modals
import EventModal from "./EventModal"; // Import AddEventModal component

const EventList = () => {
  const [events, setEvents] = useState([]);
  const [showEventModal, setShowEventModal] = useState(false);

  // Mock data (replace with your API call)
  useEffect(() => {
    const mockEvents = [
      { id: 1, title: "Meeting", date: "2024-05-13", location: "Office" },
      { id: 2, title: "Workshop", date: "2024-05-15", location: "Online" },
    ];
    setEvents(mockEvents);
  }, []);

  const handleEditClick = (eventId) => {
    // Handle logic to open EditEventModal (optional for now)
    console.log(`Edit Event: ${eventId}`);
  };

  const handleAddButtonClick = () => {
    setShowEventModal(true);
  };

  return (
    <div>
      <h2>Events</h2>
      <ul>
        {events.map((event) => (
          <li key={event.id}>
            {event.title} - {event.date} - {event.location}
            <button onClick={() => handleEditClick(event.id)}>Edit</button>
          </li>
        ))}
      </ul>
      <Button variant="primary" onClick={handleAddButtonClick}>
        Add Event
      </Button>
      <EventModal
        show={showEventModal}
        onHide={() => setShowEventModal(false)}
      />{" "}
      {/* Add the AddEventModal component */}
    </div>
  );
};

export default EventList;
