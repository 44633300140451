import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PublicCalendarPage from "./PublicCalendarPage";
import Events from "./Events";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<PublicCalendarPage />} />
          <Route path="/events" element={<Events />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
